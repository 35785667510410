export const SEMANTIC_ICONS = [
    "address book",
    "android",
    "announcement",
    "archive",
    "balance scale",
    "ban",
    "beer",
    "bell",
    "bell slash",
    "birthday cake",
    "building",
    "calendar alternate outline",
    "calendar check",
    "camera",
    "chart area",
    "check circle",
    "checkmark",
    "circle",
    "clipboard check",
    "clock",
    "cloud",
    "code",
    "coffee",
    "cogs",
    "comment",
    "comment alternate",
    "comment outline",
    "comments",
    "comments outline",
    "compass",
    "crosshairs",
    "dollar",
    "download",
    "envelope",
    "envelope open",
    "exclamation",
    "exclamation circle",
    "exclamation triangle",
    "eye",
    "file audio",
    "fire",
    "folder open",
    "gift",
    "headphones",
    "history",
    "industry",
    "info",
    "info circle",
    "key",
    "lock",
    "lock open",
    "low vision",
    "map marker",
    "map marker alternate",
    "microchip",
    "microphone",
    "microphone slash",
    "mobile",
    "money bill alternate outline",
    "paper plane",
    "phone",
    "phone square",
    "phone volume",
    "play",
    "podcast",
    "print",
    "question",
    "question circle",
    "question circle",
    "refresh",
    "remove",
    "save",
    "share square outline",
    "sitemap",
    "star",
    "tablet",
    "tag",
    "tag",
    "tasks",
    "telegram",
    "trash",
    "tv",
    "unlock alternate",
    "user",
    "user circle",
    "user secret",
    "users",
    "utensils",
    "volume up",
    "warning circle",
    "wifi",
  ];