export default function NotFound() {

    return <div className="d-flex justify-content-center align-items-center h-100 text-center">

        <div className="text-dark">
            <div style={{ fontSize: "16rem", lineHeight: "17rem" }}><b>404</b></div>
            <div style={{ fontSize: "2rem" }}>Страница не найдена</div>
        </div>

    </div>

}